@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $background-light;
  color: $white;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;

  vertical-align: top;
  font-weight: 300;
  color: $white;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $selection;
  color: $text-selection;
}

::-moz-selection {
  background-color: $selection;
  color: $text-selection;
}

button {
  cursor: pointer;
  background: transparent;
}

:disabled {
  cursor: not-allowed;
  @include opacity(0.5);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-color;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $text-color;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $text-color;
  opacity: 0.5;
}

textarea {
  min-height: 250px;
}

button {
  padding: 0;
}
