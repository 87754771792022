@import '../../../styles/colors';
@import '../../../styles/mixin';

.Main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zi-main;
}
